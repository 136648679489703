<template>
    <header class="mdc-top-app-bar mdc-top-app-bar--prominent" ref="mdcAppHeader">
        <div class="mdc-top-app-bar__row">
            <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
                <div class="gopass"></div>
                <button class="material-icons mdc-top-app-bar__navigation-icon mdc-icon-button" @click="performGoBack">keyboard_arrow_left</button>
                <span class="mdc-top-app-bar__title">{{ title || __('Skenovanie kódu') }}</span>
            </section>
        </div>
    </header>
</template>

<script>
export default {
    props: ['title', 'goBack'],
    methods: {
        performGoBack() {
            if (this.goBack) {
                return this.goBack();
            }

            window.location.href = process.env.VUE_APP_REDIRECT_BACK;
        },
    },
};
</script>

<style lang="scss" scoped>
.gopass {
    width: 59px;
    height: 14px;
    position: absolute;
    top: 22px;
    right: 16px;
    background: url($basePath + 'gopass.svg') no-repeat center;
}
</style>
