import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'moment';
import globalMixins from './mixins/globalMixins.js';
import $axios from './utilities/axios.js';
import CrudAdmin from './utilities/crudadmin.js';

window.$axios = $axios;
window.$store = store;

let app = createApp(App);

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$axios = $axios;

app.use(store).use(router).mixin(globalMixins);

CrudAdmin.installTranslatorToVue3(app, (app) => {
    window.$app = app.mount('#app');
});
