<!-- eslint-disable -->
<template>
    <AppHeader :goBack="goToInstances" />

    <main class="mdc-top-app-bar--prominent-fixed-adjust">
        <div class="container --big">
            <div class="code-reader__wrapper">
                <CodeReaderInput />

                <skipass v-if="skipass" />
                <svg v-else-if="voucherState == 'loading'" class="spinner mt-2" viewBox="0 0 50 50">
                    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
                <error-box :text="__('Neplatný kód')" v-else-if="voucherState === 'not-found'" />
                <error-box :text="__('Kód sa nepodarilo načítať. Skúste znova prosím.')" v-else-if="voucherState === 'fetch-error'" />
                <CodeReaderStats v-if="instance.has_overview" />
            </div>
        </div>
    </main>
</template>

<script>
import CodeReaderInput from '@/components/reader/CodeReaderInput.vue';
import CodeReaderStats from '@/components/reader/CodeReaderStats.vue';
import AppHeader from '@/components/base/AppHeader.vue';
import Skipass from '@/components/reader/Skipass.vue';
import ErrorBox from '@/components/base/ErrorBox.vue';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    components: {
        AppHeader,
        CodeReaderInput,
        CodeReaderStats,
        Skipass,
        ErrorBox,
    },
    data() {
        return {};
    },
    created() {
        this.resetState();
        this.setInstance(this.$route.params.posId);

        if (!this.instance) {
            this.goToInstances();
        }
    },
    computed: {
        ...mapState(['instance', 'instance']),
        ...mapState('Voucher', ['skipass', 'voucherState']),
    },
    methods: {
        ...mapActions(['setInstance']),
        ...mapMutations('Voucher', ['resetState']),
        goToInstances() {
            this.$router.push({ name: 'Instances' });
        },
    },
};
</script>

<style lang="scss">
.code-reader__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mt-2 {
    margin-top: 20px;
}
</style>
