const globalVueMixins = {
    methods: {
        // __(string) {
        //     return string;
        // },
        formatDate(date) {
            return this.$moment(date).format('DD.MM.YYYY');
        },
        formatNumber(number) {
            return new Intl.NumberFormat('sk-SK').format(number);
        },
        getAgeFromBirthDate(birthdate) {
            return this.$moment().diff(birthdate, 'years');
        },
    },
};

export default globalVueMixins;
