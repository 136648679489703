<!-- eslint-disable -->
<template>
    <div class="code-reader__stats">
        <div class="code-reader__stats-line">
            <strong class="code-reader__stats-title">
                {{ __('Všetky predané') }}
            </strong>
            <span class="code-reader__stats-value">
                {{ formatNumber(stats.sold_entrances || 0) }}
            </span>
        </div>
        <div class="code-reader__stats-line">
            <strong class="code-reader__stats-title">
                {{ __('Uplatnené') }}
            </strong>
            <span class="code-reader__stats-value color-violet">
                {{ formatNumber(stats.deactivated_entrances || 0) }}
            </span>
        </div>
        <div class="code-reader__stats-line">
            <strong class="code-reader__stats-title">
                {{ __('Zostáva uplatniť') }}
            </strong>
            <span class="code-reader__stats-value color-cyan">
                {{ formatNumber(stats.active_entrances || 0) }}
            </span>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    components: {},
    created() {},
    mounted() {
        this.getStats();

        this.setStatsRefresher();
    },
    beforeUnmount() {
        this.removeStatsRefresher();
    },
    computed: {
        ...mapState('Voucher', ['stats']),
    },
    methods: {
        ...mapActions('Voucher', ['fetchOverview']),
        async getStats() {
            await this.fetchOverview();
        },
        setStatsRefresher() {
            let minutes = 5;

            //reload stats every x minutes
            this.refreshInterval = setInterval(() => {
                this.getStats();
            }, minutes * 60 * 1000);
        },
        removeStatsRefresher() {
            clearInterval(this.refreshInterval);
        },
    },
};
</script>

<style lang="scss" scoped>
.code-reader__stats {
    margin-top: 40px;
}

.code-reader__stats-line {
    display: flex;
    align-items: center;
    height: 48px;
    justify-content: space-between;
    border-bottom: 1px solid $border;
}

.code-reader__stats-title {
    font-weight: $fwNormal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $black;
}

.code-reader__stats-value {
    font-weight: $fwNormal;
    font-size: 24px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.18px;
    color: $black;
}

.color-violet {
    color: var(--mdc-theme-secondary);
}

.color-cyan {
    color: var(--mdc-theme-tertiary);
}
</style>
