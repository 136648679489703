import { createRouter, createWebHistory } from 'vue-router';
import Homepage from '../views/Homepage.vue';
import Scanner from '../views/Scanner.vue';
import Instances from '../views/Instances.vue';
import Login from '../views/Login.vue';
import AuthMiddleware from '../middleware/AuthMiddleware';
import GuestMiddleware from '../middleware/GuestMiddleware';

const routes = [
    {
        path: '/',
        name: 'Homepage',
        component: Homepage,
    },
    {
        path: '/instances',
        name: 'Instances',
        component: Instances,
        meta: {
            middleware: [AuthMiddleware],
        },
    },
    {
        path: '/scanner/:posId',
        name: 'Scanner',
        component: Scanner,
        meta: {
            middleware: [AuthMiddleware],
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            middleware: [GuestMiddleware],
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) {
        return context.next;
    }

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        let middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware],
            context = { from, next, router, to },
            nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

export default router;
