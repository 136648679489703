<!-- eslint-disable -->
<template>
    <div>
        <div class="code-reader__input">
            <form @submit.prevent="fetchVoucher()">
                <text-field
                    :label="__('Zadajte kód')"
                    :modelValue="voucherId"
                    @update:modelValue="setVoucherId"
                    ref="skipassInput"
                    :placeholder="prevVoucherId"
                />
            </form>
            <div class="buttons">
                <button v-if="voucherId" class="mdc-button mdc-button--raised code-reader__btn --load" @click="fetchVoucher()">
                    <span class="mdc-button__label">{{ __('Načítať kód') }}</span>
                </button>
                <button class="mdc-button mdc-button--raised code-reader__btn" @click="setStartScan(true)">
                    <span class="mdc-button__label">{{ __('SKENER') }}</span>
                </button>
            </div>
        </div>
        <BarCodeReader v-if="startScan" />
    </div>
</template>

<script>
import BarCodeReader from '@/components/reader/BarCodeReader.vue';
import TextField from '@/components/form/MDCTextField.vue';
import { mapMutations, mapActions, mapState } from 'vuex';

export default {
    components: {
        TextField,
        BarCodeReader,
    },
    data() {
        return {};
    },
    created() {
        //On app refresh, we want turn of scanner
        this.setStartScan(false);
    },
    computed: {
        ...mapState(['startScan']),
        ...mapState('Voucher', ['voucherId', 'prevVoucherId', 'skipass']),
    },
    methods: {
        ...mapActions('Voucher', ['fetchVoucher']),
        ...mapMutations(['setStartScan']),
        ...mapMutations('Voucher', ['setVoucherId']),
    },
    watch: {
        voucherId() {
            this.$refs.skipassInput.$el.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
.code-reader__input {
    position: relative;
}

.code-reader__btn {
    width: 100%;
    margin-top: 15px;

    &.--load {
        background-color: #333;
    }
}
</style>
