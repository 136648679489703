import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import authStore from './modules/auth.module';
import customerStore from './modules/customer.module';
import voucherStore from './modules/voucher.module';
import _ from 'lodash';

export default createStore({
    state: {
        language: 'sk',
        instance: null,
        instances: [],
        startScan: false,
    },
    mutations: {
        setStartScan(state, bool) {
            state.startScan = bool;
        },
        setLanguage(state, code) {
            state.language = code;
        },
        setInstances(state, instances) {
            state.instances = instances;
        },
        setInstance(state, instance) {
            state.instance = instance;
        },
    },
    actions: {
        setInstance({ state, commit }, posId) {
            let instance = _.find(state.instances, { number: parseInt(posId) });

            commit('setInstance', instance);
        },
    },
    modules: {
        Auth: authStore,
        Customer: customerStore,
        Voucher: voucherStore,
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        }),
    ],
});
