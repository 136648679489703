<!-- eslint-disable -->
<template>
    <label class="mdc-text-field mdc-text-field--filled" ref="mdcTextField">
        <span class="mdc-text-field__ripple"></span>
        <span class="mdc-floating-label">{{ label }}</span>
        <input
            class="mdc-text-field__input"
            :type="type ? type : 'text'"
            v-bind="$attrs"
            :value="modelValue"
            @input="updateSelf($event.target.value)"
            ref="input"
            :placeholder="placeholder"
        />
        <span class="mdc-line-ripple"></span>
    </label>
</template>

<script>
import { MDCTextField } from '@material/textfield';

export default {
    props: {
        label: {
            type: String,
        },
        modelValue: {
            type: String,
        },
        type: {
            type: String,
        },
        placeholder: {
            type: String,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {};
    },
    mounted() {
        new MDCTextField(this.$refs.mdcTextField);
    },
    created() {},
    computed: {},
    methods: {
        updateSelf(val) {
            this.$emit('update:modelValue', val);
        },
    },
};
</script>

<style lang="scss" scoped>
.mdc-text-field {
    width: 100%;
}

.mdc-text-field__input {
    margin-top: 20px !important;
}
</style>
