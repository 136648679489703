<!-- eslint-disable -->
<template>
    <div class="spinner-wrapper">
        <svg class="spinner" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
        <p>
            {{ text }}
        </p>
    </div>
</template>

<script>
export default {
    props: ['text'],
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    text-align: center;
}
</style>
