<!-- eslint-disable -->
<template>
    <div class="barcode-reader">
        <div class="barcode-reader__wrapper">
            <div id="interactive" class="viewport scanner">
                <video />
            </div>
        </div>
        <div class="barcode-reader__mask" :class="{ '--active': observing }"></div>
        <div class="barcode-reader__close" @click="setStartScan(false)">
            <div class="material-icons">keyboard_arrow_left</div>
        </div>
    </div>
</template>

<script>
import Quagga from 'quagga';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    data() {
        return {
            timeout: 2000,
            observing: false,
        };
    },
    mounted() {
        this.bootStream();
    },
    beforeUnmount() {
        Quagga.stop();
    },
    computed: {
        ...mapState('Voucher', ['skipass']),
        ...mapState(['startScan']),
    },
    methods: {
        ...mapMutations(['setStartScan']),
        ...mapMutations('Voucher', ['setVoucherId']),
        ...mapActions('Voucher', ['fetchVoucher']),
        bootStream() {
            Quagga.init(
                {
                    inputStream: {
                        name: 'Live',
                        type: 'LiveStream',
                        constraints: {
                            width: 1920,
                            height: 1080,
                            facingMode: 'environment',
                        },
                    },
                    locate: false,
                    numOfWorkers: 4,
                    frequency: 'full',
                    decoder: {
                        readers: ['code_128_reader'],
                    },
                    locator: {
                        halfSample: true,
                        patchSize: 'medium',
                    },
                },

                (err) => {
                    if (err) {
                        alert(err);
                        return;
                    }

                    this.initializeDelayedObservation();
                }
            );

            Quagga.onDetected(this.onDetected);
        },
        clearDelayedObservation() {
            //Does not
            if (this.delayedInitialization) {
                clearTimeout(this.delayedInitialization);
            }
        },
        initializeDelayedObservation() {
            this.clearDelayedObservation();

            this.delayedInitialization = setTimeout(() => {
                this.startObserve();
            }, this.timeout);
        },
        startObserve() {
            this.observing = true;

            Quagga.start();
        },
        stopObservation() {
            this.clearDelayedObservation();
            this.observing = false;

            Quagga.stop();
        },
        onDetected(result) {
            this.setVoucherId(result.codeResult.code);
            this.fetchVoucher();
            this.setStartScan(false);

            this.stopObservation();
        },
    },
};
</script>

<style lang="scss" scoped>
.barcode-reader {
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    @include less-than(md) {
        height: calc(var(--vh, 1vh) * 100);
    }
}

.barcode-reader__wrapper {
    width: 100%;
    height: 100%;

    .scanner {
        width: 100%;
        height: 100%;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    canvas {
        display: none;
    }
}

.barcode-reader__mask {
    position: fixed;
    height: 150px;
    width: 95%;
    border: 1000px solid rgba(0, 0, 0, 0.3);
    left: 50%;
    top: 50%;
    @include border-radius(8px);
    @include translate(-50%, -50%, 0);
    @include opacity(1);

    &:before {
        content: '';
        left: -3px;
        right: -3px;
        bottom: -3px;
        top: -3px;
        position: absolute;
        border: 5px solid $white;
        @include border-radius(8px);
        background: rgb(255 0 0 / 10%);
    }

    &.--active {
        &:before {
            background: transparent;
        }
    }
}

.barcode-reader__close {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 12px;
    top: 8px;
    z-index: 110;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    cursor: pointer;

    @include less-than(md) {
        left: 4px;
        top: 4px;
    }
}
</style>
