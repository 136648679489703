<!-- eslint-disable -->
<template>
    <AppHeader :goBack="goToInstances" />
    <main class="mdc-top-app-bar--prominent-fixed-adjust">
        <div class="container --big">
            <form @submit.prevent="logUser()">
                <text-field :label="__('Meno')" v-model="name" class="mb-2" />
                <text-field :label="__('Heslo')" type="password" v-model="pass" class="mb-2" />
                <form-message v-if="errorMsg" :text="errorMsg" class="mb-2" />
                <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">{{ __('Prihlásiť') }}</span>
                </button>
            </form>
        </div>
    </main>
</template>

<script>
import AppHeader from '@/components/base/AppHeader.vue';
import TextField from '@/components/form/MDCTextField';
import FormMessage from '@/components/form/FormMessage';
import { mapActions } from 'vuex';

export default {
    components: {
        AppHeader,
        TextField,
        FormMessage,
    },
    data() {
        return {
            name: '',
            pass: '',
            errorMsg: null,
        };
    },
    mounted() {},
    created() {},
    computed: {},
    methods: {
        ...mapActions('Auth', ['loginUser']),
        async logUser() {
            this.errorMsg = null;
            const resp = await this.loginUser({ name: this.name, pass: this.pass });

            if (resp.data.error) {
                this.errorMsg = resp.data.error.detail;
            } else {
                this.$router.push('/');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.mb-2 {
    margin-bottom: 20px;
}
</style>
