<!-- eslint-disable -->
<template>
    <div class="error-box">
        <div class="error-box__info">
            {{ text }}
        </div>
        <div class="material-icons">error</div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        info: {
            type: String,
            required: false,
        },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    created() {},
    computed: {},
    methods: {},
};
</script>

<style lang="scss">
.error-box {
    margin-top: 40px;
    padding: 36px 64px;
    background: $white;
    text-align: center;
    border: 1px solid $error;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: $error;
    @include border-radius(4px);
    @include shadow(0, 16px, 24px, 0, rgba(0, 0, 0, 0.2));
}

.error-box__info {
    margin-bottom: 18px;
}
</style>
