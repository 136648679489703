import { getQueryParam } from '@/utilities/helpers.js';

const state = {
    verifying: null,
    token: null,
};

const getters = {
    isVerifying: (state) => state.verifying,
    isAuthenticated: (state) => state.token,
};

const actions = {
    getCCAuthTokenFromUrl({ dispatch }) {
        dispatch('getBearerToken', getQueryParam('cc_auth_token'));
    },
    async getBearerToken({ commit, state }, ccAuthToken) {
        if (!state.token && ccAuthToken) {
            commit('setVerifying', true);
            try {
                let response = await window.$axios.post('users/actions/sso-login', {
                    user_external_application_id: process.env.VUE_APP_ID,
                    sso_token: ccAuthToken,
                });

                commit('setToken', response?.data?.data?.bearer_token || null);
            } catch (error) {
                commit('setToken', null);
            } finally {
                commit('setVerifying', false);
            }
        }
    },
    async loginUser({ commit }, user) {
        try {
            const response = await window.$axios.post('users/actions/login', {
                user_external_application_id: process.env.VUE_APP_ID,
                login: user.name,
                password: user.pass,
            });

            // commit('setVerified', true);
            commit('setToken', response.data.data.bearer_token);

            return response;
        } catch (error) {
            return error.response;
        }
    },
};

const mutations = {
    setVerifying(state, bool) {
        state.verifying = bool;
    },
    setToken(state, token) {
        state.token = token;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
