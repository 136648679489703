import axios from 'axios';
import store from '@/store';
import router from '@/router';

const $axios = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
});

$axios.interceptors.request.use(function (config) {
    let token = store.state.Auth.token;

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

const errorInterceptor = (error) => {
    switch (error.response.status) {
        case 401: // authentication error
            console.error(error.response.status, error.message);
            //logout user
            store.commit('Auth/setToken', null);
            router.push({ name: 'Login' });
            break;

        default:
            console.error(error.response.status, error.message);
    }

    return Promise.reject(error);
};

// Interceptor for responses
const responseInterceptor = (response) => {
    switch (response.status) {
        case 200:
            // yay!
            break;
        // any other cases
        default:
        // default case
    }

    return response;
};

$axios.interceptors.response.use(responseInterceptor, errorInterceptor);

export default $axios;
