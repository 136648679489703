<!-- eslint-disable -->
<template>
    <div class="form-message" :class="[type]">
        {{ text }}
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
        },
        type: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.form-message {
    font-size: 12px;
    color: red;
}
</style>
