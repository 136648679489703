const Translator = require('gettext-translator').default;
const { getQueryParam } = require('@/utilities/helpers.js');

const CrudAdmin = {
    booted: false,

    response: null,

    translator: null,

    translates: [],

    gettextSelectors: [
        '__',
        'Gettext',
        'd__',
        'dgettext',
        'dngettext',
        'dnp__',
        'dnpgettext',
        'dp__',
        'dpgettext',
        'gettext',
        'n__',
        'ngettext',
        'np__',
        'npgettext',
        'p__',
        'pgettext',
    ],

    getAppLanguage() {
        let language = getQueryParam('language_code') || window.$store.state.language || '';

        //Storage language into
        window.$store.commit('setLanguage', language);

        return language;
    },

    async bootApp() {
        if (this.booted === true) {
            return;
        }

        var response = await window.$axios.get(process.env.VUE_APP_API_HOST + '/api/bootstrap?lang=' + this.getAppLanguage()),
            bootstrap = response.data;

        this.response = bootstrap;

        this.setTranslates(bootstrap.translates);

        window.$store.commit('setInstances', bootstrap.instances);

        this.booted = true;
    },

    async getTranslator() {
        await this.bootApp();

        return (this.translator = new Translator(this.translates));
    },

    setTranslates(translates) {
        if (typeof translates == 'string') {
            translates = JSON.parse(translates);
        }

        this.translates = translates;
    },

    async installTranslatorToVue3(app, callback) {
        var a = await this.getTranslator(),
            getSelector = function (selector) {
                return function () {
                    var s = selector in a ? selector : '__';

                    return a[s].apply(a, arguments);
                };
            };

        app.use({
            install: (app) => {
                for (var i = 0; i < this.gettextSelectors.length; i++) {
                    app.config.globalProperties[this.gettextSelectors[i]] = getSelector(this.gettextSelectors[i]);
                }
            },
        });

        callback(app);
    },
};

module.exports = CrudAdmin;
