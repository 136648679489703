<!-- eslint-disable -->
<template>
    <template v-if="isVerifying">
        <loading-spinner :text="__('Overujem užívateľa')" />
    </template>
    <template v-else-if="!isAuthenticated">
        <error-box :text="__('Autentifikácia sa nepodarila')" info="" />
    </template>
</template>

<script>
import LoadingSpinner from '@/components/base/LoadingSpinner.vue';
import ErrorBox from '@/components/base/ErrorBox.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        LoadingSpinner,
        ErrorBox,
    },
    data() {
        return {};
    },
    mounted() {},
    created() {
        if (this.isAuthenticated) {
            this.redirectUser();
        }
    },
    computed: {
        ...mapGetters('Auth', ['isVerifying', 'isAuthenticated']),
    },
    methods: {
        redirectUser() {
            if (!this.isAuthenticated) {
                this.$router.push({ name: 'Login' });
            } else {
                this.$router.push({ name: 'Instances' });
            }
        },
    },
    watch: {
        isVerifying() {
            this.redirectUser();
        },
    },
};
</script>
