import _ from 'lodash';
import moment from 'moment';

const defaultState = {
    prevVoucherId: null,
    voucherId: null,
    skipass: null,
    stats: {},
    voucherState: null,
};

const state = _.cloneDeep(defaultState);

const getters = {
    getVoucherStatus: (state) => {
        if (['fetch-error', 'update-error'].indexOf(state.voucherState) == -1) {
            if (state.skipass.state == 1) {
                return state.voucherState == 'used' ? 'applied' : 'active';
            } else if (state.skipass.state == 0) {
                return state.skipass.cancelled_at != null ? 'cancelled' : 'used';
            }
        }

        return 'error';
    },
    isVoucherExpired: (state, getters) => {
        if (getters.getVoucherStatus == 'active') {
            //if diff between currentDate and valid_to is smaller than 0
            return moment(state.skipass.valid_to).startOf('day').diff(moment().startOf('day'), 'days') < 0 ? true : false;
        }
    },
    isVoucherBeforeUse: (state, getters) => {
        if (getters.getVoucherStatus == 'active') {
            //if diff between currentDate and valid_from is bigger than 0
            return moment(state.skipass.valid_from).startOf('day').diff(moment().startOf('day'), 'days') > 0 ? true : false;
        }
    },
};

const actions = {
    async fetchVoucher({ commit, state, dispatch }) {
        //remove current voucher
        commit('setVoucher', null);

        commit('setVoucherState', 'loading');

        try {
            let response = await window.$axios.get(`entrances/?external_entrance_id=${state.voucherId}&pos_id=${this.state.instance.number}`),
                skipass = (response?.data?.data?.entrances || [])[0];

            if (skipass && skipass.customer_id) {
                await dispatch('Customer/fetchCustomer', skipass.customer_id, { root: true });
            }

            //End loading, or set skipas as not found
            commit('setVoucherState', !skipass ? 'not-found' : null);
            commit('setVoucher', skipass);

            //We need reset voucher id after successfull fetch
            commit('setVoucherId', null);
        } catch (e) {
            commit('setVoucherState', 'fetch-error');
            console.error('voucher fetch error', e);
        }
    },
    async updateVoucher({ state, commit }) {
        try {
            commit('setVoucherState', 'loading-update');

            await window.$axios.put(`entrances/${state.skipass.entrance_id}`, {
                entrance: {
                    ...state.skipass,
                    state: 0,
                },
            });

            commit('setVoucherState', 'used');
            commit('incrementDeactivatedVouchers');
            commit('decrementActiveVouchers');
        } catch (e) {
            commit('setVoucherState', 'update-error');
            console.error('voucher update error', e);
        }
    },
    async fetchOverview({ commit }) {
        try {
            const response = await window.$axios.get(
                `entrances/actions/overview?valid_from=${moment().format('YYYY-MM-DD')}&valid_to=${moment().format('YYYY-MM-DD')}&pos_id=${
                    this.state.instance.number
                }`
            );
            commit('setStats', response.data.data);
        } catch (error) {
            return error;
        }
    },
};

const mutations = {
    resetState(state) {
        for (var key in defaultState) {
            state[key] = defaultState[key];
        }
    },
    setVoucher(state, data) {
        state.skipass = data;
    },
    setVoucherId(state, data) {
        state.voucherId = data;
    },
    setPrevVoucherId(state, data) {
        state.prevVoucherId = JSON.parse(JSON.stringify(data));
    },
    setStats(state, data) {
        state.stats = data;
    },
    setVoucherState(state, obj) {
        state.voucherState = obj;
    },
    removeVoucher(state) {
        state.skipass = null;
        state.voucherId = null;
    },
    decrementActiveVouchers(state) {
        state.stats.active_entrances > 0 ? (state.stats.active_entrances -= 1) : 0;
    },
    incrementDeactivatedVouchers(state) {
        state.stats.deactivated_entrances += 1;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
