<!-- eslint-disable -->
<template>
    <app-header :title="__('Vyberte inštanciu')" />

    <main class="mdc-top-app-bar--prominent-fixed-adjust">
        <div class="container">
            <div class="instances">
                <a
                    class="instances__instance"
                    @click="$router.push({ name: 'Scanner', params: { posId: instance.number } })"
                    v-for="instance in instances"
                >
                    <span>{{ instance.name }}</span>
                    <i class="material-icons">arrow_forward_ios</i>
                </a>
            </div>
        </div>
    </main>
</template>

<script>
import AppHeader from '@/components/base/AppHeader.vue';
import { mapState, mapMutations } from 'vuex';

export default {
    components: {
        AppHeader,
    },
    data() {
        return {};
    },
    mounted() {
        this.resetState();
    },
    created() {},
    computed: {
        ...mapState(['instances']),
        ...mapState('Voucher', ['skipass', 'voucherState']),
    },
    methods: {
        ...mapMutations('Voucher', ['resetState']),
    },
};
</script>

<style lang="scss">
.instances {
    display: flex;
    flex-direction: column;

    .instances__instance {
        display: flex;
        background: rgba(33, 33, 33, 0.08);
        padding: 1rem;
        border-radius: 2px;
        margin-bottom: 1rem;
        align-items: center;
        justify-content: space-between;

        i {
            font-size: 16px;
        }
    }
}
</style>
