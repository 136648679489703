<!-- eslint-disable -->
<template>
    <router-view />
</template>

<script>
import { mapActions } from 'vuex';

export default {
    created() {
        this.getCCAuthTokenFromUrl();
    },
    mounted() {
        this.setDocumentStyles();
    },
    methods: {
        ...mapActions('Auth', ['getCCAuthTokenFromUrl']),
        setDocumentStyles() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
    },
};
</script>
