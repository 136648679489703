const state = {
    customer: null,
};

const getters = {};

const actions = {
    async fetchCustomer({ commit }, customerId) {
        const response = await window.$axios.get(`customers/${customerId}`);

        commit('setCutomer', response.data.data);
    },
};

const mutations = {
    setCutomer(state, obj) {
        state.customer = obj;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
